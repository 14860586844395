<template>
  <youtube :video-id="videoId" ref="youtube" @playing="playing" resize fit-parent />
</template>

<script>
  export default {
    name: "VideoPlayer",
    props: {
      videoId: {
        required: true,
        type: String
      },
    },
    methods: {
      playVideo () {
        this.player.playVideo()
      },
      playing () {
      }
    },
    computed: {
      player () {
        return this.$refs.youtube.player
      },
      router () {
        return this.$router
      }
    }
  }
</script>

<style scoped>
</style>
