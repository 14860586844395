<template>
  <v-container class="videoMax">
    <v-row align="center" justify="center">
      <VideoPlayer video-id="PIkH1O0vLFc" />
    </v-row>
  </v-container>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
export default {
  name: "MainPage",
  components: {
    VideoPlayer,
  }
}
</script>


<style scoped>
.videoMax{
  max-width: 80%;
  max-height: 80%;
  top: 50%;
}
</style>
